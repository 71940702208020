@import './normalize';

$color-blue: #255AB5;
$color-red: #bb1619;
$color-light-blue: #CBD9F1;
$color-yellow: #FFD500;
$color-black: #323232;

$compareTable-columns-small: 25;

body {
    color: $color-black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    max-width: 820px;
    margin: 24px 28px 24px 32px;
}

strong {
    font-weight: bold;
}

.button {
    display: inline-block;
    padding: 14px 12px;
    background-color: $color-blue;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    line-height: 1.23;

    &.red {
        background-color: $color-red;
    }
}

.link {
    display: flex;
    background: #F2F7FF;
    color: $color-blue;
    padding: 12px;
    margin: 12px 0;
    line-height: 1.46;

    i {
        flex-shrink: 0;

        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;

        margin: 2px 10px 0 0;
        
        &.army {
            background-image: url(../img/icons/army.svg);
        }

        &.humanitarian {
            background-image: url(../img/icons/humanitarian.svg);
        }

        &.host {
            background-image: url(../img/icons/host.svg);
        }
        
        &.work {
            background-image: url(../img/icons/work.svg);
        }
    }
}

header {
    position: relative;
    max-width: 650px;

    .logo {
        display: inline-block;
    
        width: 54px;
        height: 36px;
    
        margin-right: 12px;
        margin-bottom: 15px;
    
        .top,
        .bottom {
            width: 100%;
            height: 50%;
        }
    
        .top {
            background-color: $color-blue;
        }
    
        .bottom {
            background-color: $color-yellow;
        }
    }

    h1 {
        display: inline-block;

        color: $color-blue;
        font-size: 2.13em;
        font-weight: 700;
        line-height: 1.15;
        margin-bottom: 15px;

        vertical-align: bottom;

        a:visited {
            color: $color-blue;
        }
    }
    
    .menu { 
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                margin: 8px 8px 0 0;
                white-space: nowrap;

                a.active {
                    background-color: $color-black;
                }
            }
        }
    }

    .lang-selector {
        position: absolute;

        top: 0;
        right: 0;

        border: 1px solid $color-blue;
        padding-right: 12px;

        color: $color-black;
        
        select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            -webkit-appearance: none;
            // Additional resets for further consistency
            background-color: transparent;
            border: none;
            padding: 9px 12px;
            margin: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            font-weight: 700;
            cursor: inherit;
            outline: none;

            padding-right: 10px;
          }

        select::-ms-expand {
            display: none;
        }  

        &:after {
            position: absolute;
            top: 15px;
            right: 7px;
            display: block;
            content: "";
            width: 10px;
            height: 5px;
            background-color: $color-black;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        }
    }
}

main {
    margin-top: 24px;

    h2 {
        font-size: 1.33em;
        font-weight: 700;
        line-height: 1.4;
    }

    h3 {
        font-size: 1.2em;
        line-height: 1.5;
    }

    article {
        margin-top: 24px;
        max-width: 480px;
        
        .divider {
            height: 1px;
            width: 100%;

            margin-top: 24px;
            margin-bottom: 24px;

            background-color: $color-light-blue;
        }

        h3 {
            margin-top: 16px;
        }

        &.refugeesTable {
            max-width: 100%;  
            
            em {
                font-size: 0.8em;
            }
        }

        ol {
            li {
                list-style: decimal;
                list-style-position: inside;

                margin-top: 10px;
                line-height: 1.6;   
            }
        }

        p {
            margin-top: 8px;
            line-height: 1.46;
        }

        > a, li > a {
            display: block;
            margin-top: 16px;
        }

        em {
            font-style: italic;
        }
    }
}

section {
    .header {
        display: flex;
        margin-bottom: -8px;

        color: $color-black;
        font-size: 1.33em;
        font-weight: 700;
        line-height: 1.4;

        text-decoration: none;

        i {
            flex-shrink: 0;

            width: 16px;
            height: 16px;
            background-size: 16px 16px;
            background-repeat: no-repeat;

            margin: 4px 8px 0 5px;

            background-image: url(../img/icons/arrow.svg);
        }
    }
}

.financial {
    max-width: 500px;

    img {
        max-width: 100%;
    }
}

.refugees {
    h3 {
        font-weight: 700;
    }
}

.compareTable {
    margin-top: 12px;

    width: 100%;
    

    table {
        width: 100%;
    }
    
    td:nth-child(1), th:nth-child(1) {
        width: 75px;

        font-weight: 700;
        line-height: 150%;

        text-align: left;
    }
    
    td, th {
        border: 1px solid $color-light-blue;
        padding: 12px;

        font-size: 0.8em;
        line-height: 1.5em;
    }

    th {
        font-weight: 700;
    }

    td:nth-child(n+2), th:nth-child(n+2) {
        display: none;
    }

    .selector {
        position: relative;
        background-color: $color-blue;

        color: #fff;
        font-size: 0.86em;
        font-weight: 700;
        line-height: 135%;

        &--small {
            display: none;
        }

        select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            -webkit-appearance: none;
            // Additional resets for further consistency
            background-color: transparent;
            border: none;
            padding: 9px 23px 9px 12px;
            margin: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            font-weight: 700;
            line-height: inherit;
            color: inherit;
            cursor: inherit;
            outline: none;
    
            -ms-word-break: break-all; /* wraps the text in the selector so it prints properly*/
            word-break: break-all;
            /* Non standard for webkit */
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            white-space: pre-wrap;
          }
    
        select::-ms-expand {
            display: none;
        }  
    
        &:after {
            position: absolute;
            top: 45%;
            right: 15px;
            display: block;
            content: "";
            width: 10px;
            height: 5px;
            background-color: #fff;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        }
    }
}

@media (min-width: 640px)  {
    body {
        margin-left: 15%;
    }
}

@media (max-width: 710px)  {
    header {
        h1 {
            display: block;
        }
    }
}

@media (min-width: 700px)  {
    .compareTable {
        .show-big-1 {
            td:nth-child(-n+6), th:nth-child(-n+6) {
                display: table-cell;
            }
        }
    
        .show-big-2 {
            td:nth-child(n+7):nth-child(-n+11), th:nth-child(n+7):nth-child(-n+11) {
                display: table-cell;
            }
        }
    
        .show-big-3 {
            td:nth-child(n+12):nth-child(-n+15), th:nth-child(n+12):nth-child(-n+15) {
                display: table-cell;
            }
        }
    
        .show-big-4 {
            td:nth-child(n+16):nth-child(-n+19), th:nth-child(n+16):nth-child(-n+19) {
                display: table-cell;
            }
        }
    
        .show-big-5 {
            td:nth-child(n+20):nth-child(-n+25), th:nth-child(n+20):nth-child(-n+25) {
                display: table-cell;
            }
        }
    
        .show-big-6 {
            td:nth-child(n+26), th:nth-child(n+26) {
                display: table-cell;
            }
        }
    }
}

@media (max-width: 699px)  {
    .compareTable {
        .selector--small {
            display: block;
        }

        .selector--big {
            display: none;
        }

        td:nth-child(n+2) {
            display: none;
        }

        thead {
            display: none;
        }

        table {
            width: 100%;

            tbody td:nth-child(n+2), thead th:nth-child(n+2) {
                display: none;
            }

            @for $i from 2 through $compareTable-columns-small {
                &.show-small-#{$i} {
                    td:nth-child(#{$i}) {
                        display: table-cell;
                    }
                }
            }
        }
    }
}